import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SearchIcon from '@material-ui/icons/Search';
import SecurityIcon from "@material-ui/icons/Security";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Email & Website Security Check",
      subtitle:
        "Cyvali Protect is your solution to staying safe online. We work directly with leading Companies and Brands to ensure the emails and websites you browse are legitimate & not a scam or copycat site.",
      icon: SearchIcon,
      iconColor: "primary.main",
    },
    {
      title: "Safe Email & Web Browsing",
      subtitle:
        "Catch potential threats before they happen with the Cyvali Protect extension. We support most email web clients (Gmail, Outlook, & Yahoo). Scammers often set up websites & register similar sounding domain names to popular Companies & Brands in an attempt to scam customers. We know who is real & who is fake.",
      icon: SecurityIcon,
      iconColor: "#198754",
    },
    {
      title: "Scam & Phishing Alerts",
      subtitle:
        "Ensure your safety online. Cyvali provides safe browsing and protects you from scams, phishing, malware, and dangerous links. We work directly with leading Companies & Brands. We know who is legitimate & who is a fraud. No longer do you have to worry if a similar-sounding company name or website is authentic with Cyvali.",
      icon: WarningIcon,
      iconColor: "#eed202",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Grid container={true} justifyContent="center" spacing={7}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={4} key={index}>
              <Box textAlign="center">
                <Box color={item.iconColor} fontSize="4.5rem">
                  <item.icon fontSize="inherit" />
                </Box>
                <Typography variant="h5" gutterBottom={true}>
                  {item.title}
                </Typography>
                <Box mt={3}>
                  <Typography variant="subtitle1">{item.subtitle}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
