import React from "react";
import IndexPage from "./index";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import "./../util/analytics";
import Chat from "./../components/Chat";
import { ThemeProvider } from "./../util/theme";

function App(props) {
  return (
    <ThemeProvider>
      <>
        <Chat />
        <Router>
          <Switch>
            <Route exact path="/" component={IndexPage} />

            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      </>
    </ThemeProvider>
  );
}

export default App;
